import type { NextPage } from 'next'
import Typography from '@mui/material/Typography';

import PageWithoutNav from 'layouts/PageWithoutNav';
import Link from 'element/Link';
import * as routes from 'routes';
import LoginForm from 'component/forms/LoginForm';

import { Container, ContentCard, GridContainer, GridItem, H2 } from 'element';

const Login: NextPage = () => {
  return <PageWithoutNav>
    <Container maxWidth="sm">
      <ContentCard>
        <GridContainer>
          <GridItem>
            <H2>Log In</H2>
          </GridItem>
          <GridItem>
            <LoginForm />
          </GridItem>
        </GridContainer>
      </ContentCard>
    </Container>    
  </PageWithoutNav>
}

export default Login
